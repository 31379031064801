import $ from 'jquery';

const AdministrationTaskLists = {
  init() {
    // used in #index
    this.enableTaskListSelectForDeletion();

    // used in #form
    this.enableFormButtons();
    this.enableVehicleSelect();
  },

  enableTaskListSelectForDeletion() {
    $('.js-toggle-day-for-deletion').on('click', (event) => {
      const checkBoxes = $(event.target).closest('table').find('.js-delete-flag:checkbox');
      checkBoxes.click();
      return event.preventDefault();
    });
  },

  enableFormButtons() {
    $(document).ready(() => {
      // eslint-disable-next-line prefer-arrow-callback -- for consistency with below addRow lint disabling
      $('form').on('click', '.js-remove-row', function removeRow(event) {
	const row = event.target.closest('tr');

	row.remove();

	event.preventDefault();
      });

      // eslint-disable-next-line prefer-arrow-callback -- addRow function name is referenced in a ruby code comment
      $('form').on('click', '.js-add-row', function addRow(event) {
	const lastRow = document.querySelector('#pausesTable').querySelector('tr:last-of-type');

        // Every row of line stops needs a unique id.
	const time = Date.now();
	const regexp = new RegExp(event.target.dataset.id, 'g');
	const newRowHTML = event.target.dataset.fields.replace(regexp, time);
	const newRow = document.createElement('tr');
	newRow.innerHTML = newRowHTML;

	lastRow.insertAdjacentElement('afterend', newRow);

        event.preventDefault();
      });
    });
  },

  enableVehicleSelect() {
    const vehicleSelect = $('.js-vehicle-select');
    const checkAdHocbook = $('.js-tasklist-ad-hoc-bookable');
    const checkPrebook = $('.js-tasklist-prebookable');

    if (!vehicleSelect.length) return;

    vehicleSelect.on('change', (e) => {
      const optionData = $(e.target).find(':selected').data();

      checkAdHocbook.prop('checked', optionData.adHocBookable);
      checkPrebook.prop('checked', optionData.prebookable);
    });
  }
}

export default AdministrationTaskLists;
