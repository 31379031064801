import React from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import {
  stationIdFromWaypoint,
  mapStationsToSelectOptions,
  mapRequestedPointsToSelectOptions
} from '../../lib/booking_helpers';

export default class StationDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: stationIdFromWaypoint(props.waypoint),
      previouslyRequestedPointOptions: mapRequestedPointsToSelectOptions(props.previouslyRequestedPoints)
    };

    this.stationOptions = mapStationsToSelectOptions(props.stations);

    this.handleStationUpdate = this.handleStationUpdate.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: stationIdFromWaypoint(nextProps.waypoint),
      previouslyRequestedPointOptions: mapRequestedPointsToSelectOptions(nextProps.previouslyRequestedPoints)
    });
  }

  handleStationUpdate(selectedStationOrPoint, event) {
    if (event.action === 'clear') {
      this.props.handleUpdate(null);
      return;
    }

    let collection = null;

    if (selectedStationOrPoint.type === 'station') {
      collection = this.props.stations;
    } else if (selectedStationOrPoint.type === 'requested_point') {
      collection = this.props.previouslyRequestedPoints;
    }

    const selectedId = selectedStationOrPoint.value;

    const point = collection.find((candidate) => candidate.id === selectedId);

    this.setState({ value: selectedId });
    this.props.handleUpdate(point);
  }

  selectOptions() {
    const options = [
      {
        label: window.locales.BookingWizard.StationDropdown.stations,
        options: this.stationOptions
      }
    ];

    if (this.state.previouslyRequestedPointOptions.length > 0) {
      options.unshift({
        label: window.locales.BookingWizard.StationDropdown.previouslyRequestedPoints,
        options: this.state.previouslyRequestedPointOptions
      });
    }

    return options;
  }

  renderLabel() {
    if (!this.props.label) {
      return null;
    }

    return <label htmlFor="station-dropdown">{this.props.label}</label>;
  }

  render() {
    return (
      <div className="form-group">
        {this.renderLabel()}
        <Select
          id="station-dropdown"
          options={this.selectOptions()}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          value={this.stationOptions.find((o) => o.value === this.state.value) || null}
          isClearable
          isSearchable
          filterOption={createFilter({ stringify: (option) => option.label })}
          onChange={this.handleStationUpdate}
          placeholder={window.locales.BookingWizard.StationDropdown.placeholder}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    );
  }
}

StationDropdown.propTypes = {
  label: PropTypes.string,
  handleUpdate: PropTypes.func.isRequired,
  waypoint: PropTypes.shape({
    id: PropTypes.string,
    station_id: PropTypes.string,
    location_name: PropTypes.string
  }),
  stations: PropTypes.array,
  previouslyRequestedPoints: PropTypes.array
};

StationDropdown.defaultProps = {
  previouslyRequestedPoints: []
};
