import React from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import { compact, sortBy } from 'lodash';
import { Polyline } from 'react-leaflet';
import { decodePolyline } from '../../lib/decode_polyline';

import HereMap from './HereMap';
import Area from './layers/Area';
import Station from './markers/Station';
import Place from './markers/Place';
import Vehicle from './markers/Vehicle';
import Ride from './Ride';
import colors from '../../lib/colors';
import { latLngFromObj } from '../../lib/map_helpers';
import VehicleTrail from './VehicleTrail';

export default class ProductMap extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showStations: window.localStorage.showStations !== 'false'
    };

    this.toggleStationVisibility = this.toggleStationVisibility.bind(this);
  }

  getVehicleData() {
    const lastPositions = compact(this.props.vehicleLastPositions);
    const currentPositions = compact(this.props.vehicleCurrentPositions);

    return this.props.vehicles.map((vehicle) => {
      const lastPosition = lastPositions.find((p) => p.vehicle_id === vehicle.id);
      const currentPosition = currentPositions.find((p) => p.vehicle_id === vehicle.id);

      return {
        vehicle,
        currentPosition,
        lastPosition
      };
    });
  }

  toggleStationVisibility() {
    this.setState((prevState) => {
      const newValue = !prevState.showStations;
      window.localStorage.setItem('showStations', newValue.toString());
      return { showStations: newValue };
    });
  }

  renderCalculatedRoute() {
    if (!this.props.calculatedRoute) return null;
    return <Polyline positions={decodePolyline(this.props.calculatedRoute)} color="#000" />;
  }

  renderStationVisibilityToggle() {
    if (!this.props.stations || this.props.stations.length === 0) {
      return null;
    }

    return (
      <Control position="bottomleft" className="leaflet-control-layers">
        <input
            id="showStations"
            onChange={this.toggleStationVisibility}
            checked={this.state.showStations}
            type="checkbox"
        />
        &nbsp;
        <label htmlFor="showStations" className="col-form-label">
          {window.locales.HereMap.toggleStationVisibility}
        </label>
      </Control>
    );
  }

  renderStations() {
    if (!this.props.stations || !this.state.showStations) {
      return null;
    }
    return this.props.stations.map((station) => <Station key={station.id} station={station} />);
  }

  renderPlaces() {
    if (!this.props.places) {
      return null;
    }

    return this.props.places.map((place) => {
      const key = place.id;
      return <Place key={key} place={place} />;
    });
  }

  renderVehicles() {
    return this.getVehicleData().map(({ vehicle, currentPosition, lastPosition }) => {
      if (currentPosition || lastPosition) {
        return (
          <Vehicle
            animationDuration={5}
            key={vehicle.id}
            position={latLngFromObj(currentPosition || lastPosition)}
            heading={currentPosition?.heading || lastPosition?.heading || 0}
            color={colors.colorForVehicle(vehicle)}
            vehicle={vehicle}
          />
        );
        // eslint-disable-next-line no-else-return -- "else return" easier to grasp together as the "if" block is longer
      } else {
        return null;
      }
    });
  }

  renderVehicleTrail() {
    if (!this.props.trail) return null;
    return <VehicleTrail positions={this.props.trail} />;
  }

  renderRides() {
    if (!this.props.rides) {
      return;
    }
    return this.props.rides.map((ride) => {
      const color = ride.vehicle ? colors.colorForVehicle(ride.vehicle) : '#000000';

      return <Ride key={ride.id} ride={ride} color={color} />;
    });
  }

  renderArea() {
    const areas = sortBy(compact([this.props.drtArea, this.props.intermodalArea]), "z_index");

    return areas.map((area) => <Area key={`area-${area.id}`}
                                     area={area.area}
                                     color={area.color}
                                     opacity={area.opacity}
                                     weight={area.stroke_weight}
                                     fillColor={area.fill_color}
                                     fillOpacity={area.fill_opacity} />);

  }

  render() {
    return (
      <HereMap boundingBox={this.props.boundingBox}>
        {this.renderArea()}
        {this.renderStationVisibilityToggle()}
        {this.renderStations()}
        {this.renderPlaces()}
        {this.renderVehicles()}
        {this.renderRides()}
        {this.renderCalculatedRoute()}
        {this.renderVehicleTrail()}
      </HereMap>
    );
  }
}

ProductMap.propTypes = {
  drtArea: PropTypes.object,
  intermodalArea: PropTypes.object,
  rides: PropTypes.array,
  stations: PropTypes.array,
  places: PropTypes.array,
  vehicles: PropTypes.array.isRequired,
  vehicleCurrentPositions: PropTypes.array.isRequired,
  vehicleLastPositions: PropTypes.array.isRequired,
  trail: PropTypes.array,
  calculatedRoute: PropTypes.string,
  boundingBox: PropTypes.object
};
