import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Form, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import PrebookingShortcut from './PrebookingShortcut.jsx';

export default class PreBookingShortcutsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.submitTimeOffset = this.submitTimeOffset.bind(this);
    this.setSelectedTime = this.setSelectedTime.bind(this);
  }

  defaultState() {
    return {
      selectedTime: null,
      selectedOption: 'plusOneDay'
    };
  }

  setSelectedTime(time) {
    this.setState({ selectedTime: time });
  }

  handleOptionChange(event) {
    this.setState({ selectedOption: event.target.value });
  }

  handleModalClose() {
    this.setState(this.defaultState());
  }

  submitTimeOffset() {
    if (this.state.selectedOption == 'plusOneDay') {
      this.props.setRequestedTime(addDays(this.props.requestedTime, 1));
    } else if (this.state.selectedOption == 'plusOneWeek') {
      this.props.setRequestedTime(addDays(this.props.requestedTime, 7));
    } else {
      this.props.setRequestedTime(this.state.selectedTime);
    }
    this.props.toggle();
  }

  renderPrebookingShortcut() {
    return (
      <PrebookingShortcut
        prebookingThresholdMin={this.props.prebookingThresholdMin}
        prebookingThresholdMax={this.props.prebookingThresholdMax}
        timezone={this.props.timezone}
        handleUpdate={this.setSelectedTime}
        requestedTime={this.state.selectedTime || this.props.requestedTime}
        disabled={this.state.selectedOption != 'customDate'}
      />
    );
  }

  showTitle() {
    if (this.props.requestedTimeForWaypoint == 'origin') {
      return window.locales.BookingWizard.PreBookingShortcutsModal.titleDeparture;
    }
    return window.locales.BookingWizard.PreBookingShortcutsModal.titleArrival;
  }

  render() {
    return (
      <Modal isOpen={this.props.show} onClosed={this.handleModalClose} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>{this.showTitle()}</ModalHeader>
        <ModalBody className="always-show-feedback">
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="plusOneDay"
                  checked={this.state.selectedOption === 'plusOneDay'}
                  onChange={this.handleOptionChange}
                  className="form-check-input"
                />
                {window.locales.BookingWizard.PreBookingShortcutsModal.plusOneDay}
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="plusOneWeek"
                  checked={this.state.selectedOption === 'plusOneWeek'}
                  onChange={this.handleOptionChange}
                  className="form-check-input"
                />
                {window.locales.BookingWizard.PreBookingShortcutsModal.plusOneWeek}
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="customDate"
                  checked={this.state.selectedOption === 'customDate'}
                  onChange={this.handleOptionChange}
                  className="form-check-input"
                />
                {window.locales.BookingWizard.PreBookingShortcutsModal.customDate}
                {this.renderPrebookingShortcut()}
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.submitTimeOffset}>
            {' '}
            {window.locales.BookingWizard.buttons.ok}
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            {' '}
            {window.locales.BookingWizard.buttons.cancelModal}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

PreBookingShortcutsModal.propTypes = {
  requestedTime: PropTypes.instanceOf(Date),
  requestedTimeForWaypoint: PropTypes.oneOf(['origin', 'destination']),
  prebookingThresholdMin: PropTypes.number.isRequired,
  prebookingThresholdMax: PropTypes.number.isRequired,
  timezone: PropTypes.shape({
    identifier: PropTypes.string,
    utc_offset: PropTypes.number
  }),
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  setRequestedTime: PropTypes.func.isRequired
};
