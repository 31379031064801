import $ from 'jquery';
import { setupTomSelect } from '../../lib/form_helpers';

const AdministrationTasks = {
  init() {
    this.handleDateTypeChange();

    Array.from(document.querySelectorAll('.cancellation-statement')).forEach(element => {
      setupTomSelect(element);
    });
  },

  handleDateTypeChange() {
   $('.js-radio-completion').on('click', (e) => {  
      if (e.target.value === '1') {
        $('.reported-completed-at').prop('disabled', true);
      } else {
        $('.reported-completed-at').prop('disabled', false);
      }
    });

    $('.js-radio-cancellation').on('click', (e) => {
      if (e.target.value === '1') {
        $('.reported-cancelled-at').prop('disabled', true);
        $('.cancellation-statement').prop('disabled', true);
        $('.final-driver-id').prop('disabled', true);
      } else {
        $('.reported-cancelled-at').prop('disabled', false);
        $('.cancellation-statement').prop('disabled', false);
        $('.final-driver-id').prop('disabled', false);
      }
    });
  }
}

export default AdministrationTasks;
